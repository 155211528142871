import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HarryImage = "https://content.justrightsteve.com/HarrysCorner.jpg";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard() {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 810 }}>
      <CardHeader
        sx={{ color: 'black', fontSize: '28px', fontWeight: 'bold' }}
        title="Harry's Corner"
      />
      <CardMedia
        component="img"
        height="200"
        image={HarryImage}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.6 }}>
        <br/>
        <br/>UHL Trivia 1: What Is The All Time Record For Lowest UHL Goals Against Average In 1 Season? Hint: 2012-13
        <br/>
        <br/>UHL Trivia 2: What Is The All Time Record For Lowest Number Of Goals Scored In 1 Night Of UHL Play? Hint: September 22, 2019.
        </Typography>
      </CardContent>
      <CardContent>
        <Typography variant="h5">
          Last Week's Trivia
        </Typography>
      </CardContent>
      <CardActions>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
         <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.6 }}>
          <br/> NHL Trivia Answer: The Toronto Maple Leafs Won The Stanley Cup In 1967, Final Year Of The Original 6 Era & Haven’t Won It Since.
         </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
